import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class EmailService {
	constructor(private http:HttpClient) { }

	sendMessage(title,date,description,place_location,youAre,happened,placeType,reportPolice,policeAttitude,acceptedToSendEmail){
		const obj = {title: title,date:date, description:description, youAre:youAre,happened:happened,place_location:place_location,placeType:placeType,reportPolice:reportPolice,policeAttitude:policeAttitude, acceptedToSendEmail:acceptedToSendEmail};
		const myJSON = JSON.stringify(obj);
		this.http.post("assets/send_email.php",myJSON,{responseType: 'text'}).subscribe(a=>{
			console.log(a);
		});
	}

	sendSimpleCheckMail(){
		var email;
		this.http.post("assets/send_check_email.php",{email:'risteskaa@gmail.com'},{responseType: 'text'}).subscribe(a=>{
			console.log(a);
		});
	}


	
}
