<div class="container page">
	<div class="row">
		<div class="col-12">
			 <span [innerHTML]= "pageData?.text" ></span>
			

		</div>
	</div>
</div>

