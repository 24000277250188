export default class Report{
	id:string;
	title:string;
	description:string;
	reportDate:any;
	time:string;
	reportCat:any;
	reportCategories:any;
	youAre:string;
	happened:string;
	placeType:string;
	reportPolice:string;
	policeAttitude:string;
	policeAnswer:string[];
	published=false;
	mapData:any;
	place_location:any;
	news_link:any;
	news_link_2:any;
	image:any;
	perpetratorHeight:any;
	perpetratorAge:any;
	perpetratorWeight:any;
	perpetratorHairColor:any;
	perpetratorFeatures:any;
	date:any;
	acceptedToSend=false;
	acceptedToSendEmail=false;
}