import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { StaticPagesService } from 'src/app/shared/services/static-pages.service';
import {Router, ActivatedRoute} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-frontend-page-ios',
  templateUrl: './frontend-page-ios.component.html',
  styleUrls: ['./frontend-page-ios.component.scss']
})
export class FrontendPageIosComponent implements OnInit {

  id;
pageData;
currentL;
  constructor(private fb:FormBuilder, private staticpage:StaticPagesService, private rout:ActivatedRoute, private translate:TranslateService, private router:Router) { }
  ngOnInit(): void {
    this.id = this.rout.snapshot.paramMap.get('page');
    let staticpageListener=this.staticpage.staticPageGetData(this.id).subscribe(a=>{
      this.pageData=a;
      staticpageListener.unsubscribe();
    });
    this.currentL=this.translate.currentLang;
        
  }

}
