<app-frontend-header></app-frontend-header>
<form [formGroup]="freportAdd" (ngSubmit)="onSubmit(freportAdd)">
	<div class="container page">
		<div class="row">
			<div class="col-12 subtext"><a href="/">Почетна</a> / {{"Поднеси нова пријава"|translate}}</div>
			<div class="col-12 title">{{'Поднеси нова пријава'| translate}}</div>
			<div class="col-12 req">{{"Полињата означени со * се задолжителни"|translate}}</div>
		</div>
		<div class="row">
			<div class="col-md-6 col-xs-12">
				<div class="form-group">
					<label class="label">{{"Наслов"|translate}}</label>
					<span class="mandatory">*</span>
					<input type="text" id="title" formControlName="title" class="form-control"
					[ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
					<div *ngIf="submitted && f.title.errors" class="invalid-feedback">
						<div *ngIf="f.title.errors.required">					
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="report_desc" class="label">{{"Ве молиме раскажете ни повеќе детали за тоа што ви се случило"|translate}}
					</label>
					<span class="mandatory">*</span>
					<textarea class="form-control" id="description" formControlName="description"
					[ngClass]="{ 'is-invalid': submitted && f.description.errors }" style="min-height: 300px;"></textarea>
					<div *ngIf="submitted && f.description.errors" class="invalid-feedback">
						<div *ngIf="f.description.errors.required">					
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>

			<!-- <div class="group">
				<label for="report_date">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
				<div class="container">
					<div class="row">
						<div class="form-group">
							<div class="input-group">
								<input  class="form-control" type="date" name="reportDate" [(ngModel)]="report.reportDate" #reportDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && reportDate.invalid }" required>
								<input type="type" name="time" placeholder="{{'Пр. 12:00, 12:35'|translate}}" [(ngModel)]="report.time">
							</div>
							<div *ngIf="f.submitted && reportDate.invalid" class="invalid-feedback" style="display: block;">
								<span>{{'Ова поле е задолжително'|translate}}</span>
							</div>
						</div>
					</div>
				</div>
			</div> -->

			<div class="form-group">
				<label for="blog_date" class="label">{{"Датум и време"|translate}}<span class="mandatory">*</span></label>
				<div class="row">
					<div class="col-6">		<input type="date" formControlName="reportDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.reportDate.errors }" />
					</div>
					<div class="col-6">		<input type="type" formControlName="time" placeholder="{{'Пр. 12:00, 12:35'|translate}}" class="form-control">
					</div>
				</div>
				<div *ngIf="submitted && f.reportDate.errors" class="invalid-feedback">
					<div *ngIf="f.reportDate.errors.required">									<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="reportCat" class="label">{{"Категории"|translate}}</label>
				<span class="mandatory">*</span>
				<div *ngFor="let category of categories; let i=index">
					<input type="checkbox"
					id="bla+{{category.id}}"  name="reportCat"  [value]="category.name"
					(change)="isCategorySelected($event,category.name)" id="{{category.name}}">
					<label for="{{category.name}}" style="cursor: pointer;"> {{category.name | translate}}</label>
				</div>

				<div *ngIf="submitted && !checkedCat" class="invalid-feedback mandatory_line" >
					<div *ngIf="submitted && !checkedCat">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>

			</div>
			<div class="form-group">
				<label for="report_a_y" class="label">{{"Вие сте"|translate}}<span class="mandatory">*</span></label>
				<div *ngFor="let a_y of are_you" >
					<input type="radio"  name="youAre" formControlName="youAre" value="{{a_y.name}}" id="{{a_y.name}}">
					<label for="{{a_y.name}}">{{a_y.name | translate}}</label>
				</div>
				<div *ngIf="submitted && f.youAre.errors" class="invalid-feedback mandatory_line">									
					<div *ngIf="f.youAre.errors.required"><span>{{'Ова поле е задолжително'|translate}}</span></div>
				</div>
			</div>

			<!-- <div class="form-group">
				<label for="report_a_y">{{"Вие сте"|translate}}<span class="mandatory">*</span></label>
				<div *ngFor="let a_y of are_you" >
					<input type="radio"  name="ayRadio"  (change)="on_a_y_Change(a_y)" id="{{a_y.name}}">
					<label for="{{a_y.name}}">{{a_y.name | translate}}</label>
				</div>
				<div *ngIf="f.submitted && youAre==undefined" class="invalid-feedback mandatory_line" >
					<span>{{'Ова поле е задолжително'|translate}}</span>
				</div>

			</div> -->
			<div class="form-group">
				<label for="report_a_y" class="label">{{"Се случува"|translate}}</label>
				<span class="mandatory">*</span>
				<div *ngFor="let happened of it_happened" >
					<input type="radio"  name="happened" formControlName="happened" value="{{happened.name}}" id="{{happened.name}}">
					<label for="{{happened.name}}"> {{happened.name | translate}}</label>
				</div>
				<div *ngIf="submitted && f.happened.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.happened.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="form-group" >
				<label for="placeType" class="label">{{'Тип на место'|translate}}</label>
				<span class="mandatory">*</span>
				<br>
				<select class="form-control" name="placeType" formControlName="placeType">
					<option *ngFor="let place of place_type"  value="{{place.name}}">{{place.name|translate}}</option>
				</select>
				<div *ngIf="submitted && f.placeType.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.placeType.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="report_police" class="label">{{"Пријавивте ли во полиција?"|translate}}</label><span class="mandatory">*</span>
				<div *ngFor="let pol of report_police" >
					<input type="radio" name="reportPolice" formControlName="reportPolice" 
					value="{{pol.name}}" id="{{pol.id}}_{{pol.name}}">
					<label for="{{pol.id}}_{{pol.name}}">{{pol.name | translate}}</label>
				</div>

				<div *ngIf="submitted && f.reportPolice.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.reportPolice.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="police_answer" class="label">{{"Каков беше нивниот одговор?"|translate}}</label><span class="mandatory">*</span>
				<div *ngFor="let answer of police_answer">
					<input type="checkbox" name="policeAnswer" value="answer.name" 
					(change)="isPoliceAnswerSelected($event,answer.name)" id="{{answer.id}}_{{answer.name}}">
					<label for="{{answer.id}}_{{answer.name}}">{{answer.name | translate}}</label>
				</div>
				<div *ngIf="submitted && !checkedAnswer" class="invalid-feedback mandatory_line" >
					<div *ngIf="submitted && !checkedAnswer">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="police_attitude" class="label">{{"Колку сте задоволни од односот на полицијата?"|translate}}</label>
				<span class="mandatory">*</span>
				<div *ngFor="let atti of police_attitude" >
					<input type="radio" name="policeAttitude" formControlName="policeAttitude" value="{{atti.name}}" id="{{atti.id}}_{{atti.name}}">
					<label for="{{atti.id}}_{{atti.name}}">{{atti.name | translate}}</label>
				</div>
				<div *ngIf="submitted && f.policeAttitude.errors" class="invalid-feedback mandatory_line" >
					<div *ngIf="f.policeAttitude.errors.required">
						<span>{{'Ова поле е задолжително'|translate}}</span>
					</div>
				</div>
			</div>
			

		</div>
		<div class="col-md-6 col-xs-12">
			<div class="group">
				<div class="mymap form-group">
					<app-map (passMapCoords)="getCoords($event)"></app-map>
					<div *ngIf="submitted && f.mapData.errors" class="invalid-feedback mandatory_line" >
						<div *ngIf="f.mapData.errors.required">
							<span>{{'Ова поле е задолжително'|translate}}</span>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="label">{{"Прецизирај го местото на локацијата"|translate}}</label>
					<span class="mandatory">*</span>
					<div class="subtext">{{"Пример: позади Рамстор, Железничка, Скопје"|translate}}</div>
					<input type="text" name="place_location" formControlName="place_location" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place_location.errors }">
					<div *ngIf="submitted && f.place_location.errors" class="invalid-feedback">
						<div *ngIf="f.place_location.errors.required">
							<span>{{'Ова поле е задолжително'|tanslate}}</span></div>
						</div>
					</div>				
					<div>
						<div>{{"Линк од вести"|translate}}</div>
						<input class="form-control" type="text" name="news_link" formControlName="news_link">
					</div>
					<div>
						<div>{{"Надворешен видео линк"|translate}}</div>
						<input class="form-control" type="text" name="news_link_2" formControlName="news_link_2">
					</div>
					<div>
						<div>{{"Прикачи фотографија"|translate}}</div>
						<input class="form-control" type="file"  (change)="uploadImage($event, f.title)" accept=".png,.jpg" >
						<div class="progress">
							<div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent | async) + '%'" [attr.aria-valuenow]="(uploadPercent | async)" aria-valuemin="0" aria-valuemax="100"></div>
						</div>

					</div>
				</div>
				<div class="col-12 group">
					<label class="notMandatory">{{"Незадолжителни полиња - Опис на сторителот"|translate}}</label>
					<div class="row pad">
						<div class="col-6">{{"Приближна возраст"|translate}}</div>
						<div class="col-6"><input class="form-control" type="text" name="perpetratorAge" formControlName="perpetratorAge"></div>
					</div>
					<div class="row pad">
						<div class="col-6">{{"Висина"|translate}}</div>
						<div class="col-6"><input class="form-control" type="text" name="perpetratorHeight" formControlName="perpetratorHeight"></div>
					</div>
					<div class="row pad">
						<div class="col-6">{{"Тежина"|translate}}</div>
						<div class="col-6"><input class="form-control" type="text" name="perpetratorWeight" formControlName="perpetratorWeight"></div>
					</div>
					
					<div class="row pad">
						<div class="col-6">{{"Боја на коса"|translate}}</div>
						<div class="col-6"><input class="form-control" type="text"
							name="perpetratorHairColor" formControlName="perpetratorHairColor"></div>
						</div>
						<div class="row pad">
							<div class="col-6">{{"Специфични белези (облека, тетоважи, накит итн.)"|translate}}</div>
							<div class="col-6"><textarea class="form-control" type="textarea" name="perpetratorFeatures" formControlName="perpetratorFeatures"></textarea></div>
						</div>

						<div class="row pad acceptToSend">
							<div class="col-8">{{"Прифаќам мојата пријава да биде разгледана и препратена до соодветните организации."|translate}}</div>
							<div class="col-4"><input class="form-control" type="checkbox" name="acceptedToSend" formControlName="acceptedToSend" (change)="acceptedToSend($event)"/></div>
						</div>

						<div class="row pad" *ngIf="openEmailBox">
							<div class="col-6">{{"За понатамошен контакт ве молиме внесете ја вашата е-адреса"|translate}}</div>
							<div class="col-6"><input class="form-control" type="text" name="acceptedToSendEmail" formControlName="acceptedToSendEmail">
							
						</div>
						<div class="col-12">
							<div *ngIf="submitted && f.acceptedToSendEmail.errors" class="invalid-feedback mandatory_line" >
								<div *ngIf="f.acceptedToSendEmail.errors.required">
									<span>{{'Ова поле е задолжително'|translate}}</span>
								</div>
							</div>
						</div>

						</div><br>
						<div class="row pad">
							<div class="col-6">
								<ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"  
								(success)="handleSuccessRecaptcha($event)" [ngClass]="{ 'is-invalid': submitted && f.recaptcha.errors }">
							</ngx-recaptcha2>
							<div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
								<div *ngIf="f.recaptcha.errors.required">
									<span>{{'Ова поле е задолжително'|translate}}</span></div>
								</div>
							</div>
						</div>
						<button class="btn btn-primary" type="submit">{{"Потврди"|translate}}</button>


					</div>


				</div>

			</div>

		</div>
	</form>
	<app-frontend-footer></app-frontend-footer>